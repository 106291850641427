module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://eham-admin.mobydigg.de/graphql","verbose":true,"schema":{"typePrefix":"Wp","timeout":120000,"perPage":5,"requestConcurrency":5,"previewRequestConcurrency":5,"queryDepth":15,"circularQueryLimit":5},"develop":{"nodeUpdateInterval":3000,"hardCacheMediaFiles":true,"hardCacheData":true},"production":{"allow404Images":true,"hardCacheMediaFiles":false,"allow401Images":false},"debug":{"graphql":{"writeQueriesToDisk":true,"showQueryOnError":true,"showQueryVarsOnError":true,"copyQueryOnError":false,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"printIntrospectionDiff":false},"timeBuildSteps":true,"preview":true,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"excludeFieldNames":["blocksJSON","saveContent"],"type":{"MediaItem":{"localFile":{"maxFileSizeBytes":67108864,"excludeByMimeTypes":[],"requestConcurrency":100},"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"Post":{"limit":null},"Project":{"limit":null},"CoreParagraphBlockAttributes":{"exclude":true},"CoreParagraphBlockAttributesV2":{"exclude":true},"CorePullquoteBlockAttributes":{"exclude":true},"User":{"exclude":true},"UserRole":{"exclude":true},"Comment":{"exclude":true},"PostFormat":{"exclude":true},"Tag":{"exclude":true},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"rgb(249, 244, 241)"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-tracking/gatsby-browser.js'),
      options: {"plugins":[],"debug":false,"googleAnalytics":{"trackingId":"G-K5MF2RTQHB","autoStart":false,"anonymize":true,"controlCookieName":"EhamCookieConsent","cookieFlags":"secure;samesite=none"},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
